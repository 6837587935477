// aes解密

import { CryptoJS } from "./aes.js";

export const jiemi = function (word) {
  const words = word.replace(/(^\s*)|(\s*$)/g, "");
  const key = CryptoJS.enc.Utf8.parse("jtkpwangluocom12"); // 十六位十六进制数作为秘钥
  const iv = CryptoJS.enc.Utf8.parse("1234567890123456"); // 十六位十六进制数作为秘钥偏移量
  const encryptedHexStr = CryptoJS.enc.Base64.parse(words); // 使用Base64模式
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  // console.log(word.length)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  // console.log(srcs)
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
};
export default {
  jiemi,
};
