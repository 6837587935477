import Vue from "vue";
import Vuex from "vuex";
import { getUserInfo } from "@/utlis/api";
import { Message } from "element-ui";
// import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    phoneNumber: "", // 手机号码
    phoneNumberRule: "", // 手机校验提示信息
    keyword: "", // 搜索内容
    winxinScent_id: "", // 微信id
    companyInfo: {}, // 企业基本信息
    companyState: 1, // 企业是否解锁状态
    userInfo: null,
    city: null,
    fuwuData: [],
    homeData: {
      defaultId: "",
    },
  },
  getters: {},
  mutations: {
    changePhoneNumber(state, val) {
      state.phoneNumber = val;
    },
    changeKeyWord(state, val) {
      state.keyword = val;
    },
    removeToken(state) {
      // window.location.href = "/login";
      localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("stateSave");
      window.location.href = "/";
    },
    reomveUserInfo() {},
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    setWinxinScentId(state, newId) {
      state.winxinScent_id = newId;
    }
  },
  actions: {
    async setUserInfo({ commit }) {
      try {
        const { data, message, status } = await getUserInfo();
        if (status !== 200) {
          return Message({
            type: "error",
            message: message,
          });
        }
        commit("SET_USERINFO", data);
      } catch (err) {
        Message.error(err);
        commit("SET_USERINFO", {});
      }
    },
  },
  modules: {},
  plugins: [],
});
