export const zizhiStoreChildren = [
  {
    path: "person",
    name: "person",
    component: () => import("@/views/homeView/zizhiStore/person/person"),
    meta: { requireAuth: true, title: "人员" },
  },
  //   {
  //     path: "anxurenyuan",
  //     name: "anxurenyuan",
  //     component: () =>
  //       import("@/views/homeView/zizhiStore/anxujirenyuan/anxujirenyuan"),
  //     meta: { requireAuth: true, title: "安许及人员" },
  //   },
  //xiaoan
  //暂时注释申报材料
  {
    path: "shenbao",
    name: "shenbao",
    component: () =>
      import("@/views/homeView/zizhiStore/shenbaocailiao/shenbaocailiao"),
    meta: { requireAuth: true, title: "申报材料备份" },
  },
];
// 首页一级路由数据
export const navList = [
  {
    path: "/",
    component: () => import("@/views/homeView/HomeView"),
    meta: { title: "首页" },
    redirect: "home",
    name: "home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/homeView/home/home"),
        meta: { title: "首页" },
      },
      {
        path: "zizhiStore",
        name: "",
        component: () => import("@/views/homeView/zizhiStore/zizhiStore"),
        meta: { requireAuth: true, title: "资质库" },
        children: [
          ...zizhiStoreChildren,
          {
            path: "/",
            component: () => import("@/views/homeView/zizhiStore/index/index"),
            meta: { requireAuth: true, title: "人员" },
          },
        ],
      },
      {
        path: "talentPool",
        component: () => import("@/views/homeView/talentPool/index"),
        meta: { requireAuth: true, title: "人才库" },
      },
      {
        path: "tongzhi",
        redirect: "/tongzhi/notice",
        component: () => import("@/views/homeView/tongzhi/index"),
        meta: { title: "动态" },
        children: [
          {
            path: "trendDetail",
            name: "trendDetail",
            component: () => import("@/views/homeView/tongzhi/trendDetail"),
            meta: { title: "动态详情" },
          },
          {
            path: "notice",
            name: "notice",
            component: () => import("@/views/homeView/tongzhi/tongzhi"),
            meta: { title: "动态通知" },
          },
        ],
      },
    ],
  },

  {
    path: "/myinformation", // 个人信息
    component: () =>
      //  @/views/homeView/myinformation/personalData/personalData
      import("@/views/homeView/myinformation/myinformation"),
    //  redirect: "myinformation/personalData",
    meta: { requireAuth: true },
  },
  {
    path: "/personalCenter", // 个人中心
    component: () =>
      import("@/views/homeView/myinformation/personalCenter/index"),
    meta: { requireAuth: true },
    children: [
      {
        path: "/personalData", // 个人资料
        component: () =>
          import("@/views/homeView/myinformation/personalCenter/personalData"),
        meta: { requireAuth: true, title: "个人资料" },
      },
      {
        path: "/authentication", // 企业认证
        component: () =>
          import(
            "@/views/homeView/myinformation/personalCenter/authentication"
          ),
        meta: { requireAuth: true, title: "企业认证" },
      },
      {
        path:"/dataSynchronous",//数据同步
        component:()=>import("@/views/homeView/myinformation/personalCenter/dataSynchronous"),
        meta: { requireAuth: true, title: "数据同步" },

      },
      {
        path: "/orderManagement", // 订单管理
        component: () =>
          import(
            "@/views/homeView/myinformation/personalCenter/orderManagement"
          ),
        meta: { requireAuth: true, title: "订单管理" },
      },
      {
        path: "/personalPhone", // 联系方式
        component: () =>
          import("@/views/homeView/myinformation/personalCenter/personalPhone"),
        meta: { requireAuth: true, title: " 联系方式" },
      },
      {
        path: "/editPassword", // 修改密码
        component: () =>
          import("@/views/homeView/myinformation/personalCenter/editPassword"),
        meta: { requireAuth: true, title: "修改密码" },
      },
      {
        path: "/messageFeedback", // 留言反馈
        component: () =>
          import(
            "@/views/homeView/myinformation/personalCenter/messageFeedback"
          ),
        meta: { requireAuth: true, title: "留言反馈" },
      },
      {
        path: "/subAccount", // 子账号管理
        component: () =>
          import("@/views/homeView/myinformation/personalCenter/subAccount"),
        meta: { requireAuth: true, title: "子账号管理" },
      },
      {
        path: "/operationRecords", // 操作记录
        component: () =>
          import(
            "@/views/homeView/myinformation/personalCenter/operationRecords"
          ),
        meta: { requireAuth: true, title: "操作记录" },
      },
    ],
  },
];

export const routes = navList.concat([
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/login.vue"),
    //   children: [{ path: '', name: 'user', component:  }]
  },
  {
    path: "/MyAuthentication",
    name: "认证",
    component: () => import("@/views/authentication/authentication.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/vip",
    name: "vip",
    component: () => import("@/views/VIP/index.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about/index.vue"),
    meta: { requireAuth: false },
  },
  {
    path: "/massageCenter", // 消息中心
    name: "massageCenter",
    component: () => import("@/views/massageCenter/index.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/components/notFound.vue"),
  },
]);
