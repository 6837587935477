import axios from "axios";
import { jiemi } from "./jiemi";
import store from "@/store/index";
import { Message } from "element-ui";

export const baseURL = "https://jqgj.jiantongcha.cn"; //线上地址
// export const baseURL = "http://localhost:8080/api";
const refreshToken = localStorage.getItem("refreshToken"); // 刷新token
const request = axios.create({
  timeout: 30000,
  withCredentials: true,
  retry: 3,
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: baseURL,
});

// 添加请求拦截器
request.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem("token");
    config.headers.token = token;
    return Promise.resolve(config);
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    const res = JSON.parse(jiemi(response.data));
    if (res.status === 401) {
      let url = response.config.url;
      let whiteUrl = [
        "/u/message_count",
        "/u/certifi_info",
        "/q/list",
        "/p/list",
        "/u/isWebLogin"
      ];
      if (!whiteUrl.includes(url)) {
        store.commit("removeToken");
      }
      // 表示token失效 从新获取token
      // store.commit("removeToken");
      // window.location.href = "/login";
      // localStorage.setItem("token", localStorage.getItem("refreshToken"));
    } else if (res.status === 500) {
      Message({
        type: "error",
        message: "接口错误，请刷新或联系管理员！" + res.message,
      });
    }
    if (response.status === 200) {
      return Promise.resolve(res);
    } else {
      console.log("2222222");
      return Promise.reject(res);
    }
  },
  function (error) {
    console.log(error, "2222222222222");
    Message({
      type: "error",
      message: "接口错误，请刷新或联系管理员！" + error.message,
    });
    return Promise.reject(error);
  }
);

// 使用try和catch处理请求错误
request.interceptors.request.use((config) => {
  try {
    // 这里是请求的处理逻辑，可以包含参数验证等其他错误处理逻辑。
    return Promise.resolve(config);
  } catch (error) {
    console.error("请求拦截器发生错误:", error); // 可以记录错误信息或抛出异常等处理方式。
    return Promise.reject(error); // 将错误传递给下一个拦截器或请求调用者。
  }
});

// 使用try和catch处理响应错误
request.interceptors.response.use((response) => {
  try {
    // 这里是响应的处理逻辑，可以包含参数验证等其他错误处理逻辑。
    return Promise.resolve(response);
  } catch (error) {
    console.error("响应拦截器发生错误:", error); // 可以记录错误信息或抛出异常等处理方式。
    return Promise.reject(error); // 将错误传递给下一个拦截器或请求调用者。
  }
});
export default request;
