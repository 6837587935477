export function debounce(fn, wait) {
  let timer = null;
  return function () {
    clearTimeout(timer);
    let context = this;
    let args = arguments;
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
}

export function throttle(fn, wait) {
  let lastTime = 0;
  return function () {
    let context = this;
    let args = arguments;
    let now = new Date().getTime();
    if (now - lastTime > wait) {
      fn.apply(context, args);
      lastTime = now;
    }
  };
}

export function deepClone(obj, hash = new WeakMap()) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime()); // 复制日期值
  }
  if (obj instanceof RegExp) {
    // 复制正则表达式的源字符串和标志
    const flags = obj.flags || "";
    return new RegExp(obj.source, flags);
  }
  if (obj instanceof Function) {
    return obj; // 函数引用传递
  }
  if (hash.has(obj)) {
    return hash.get(obj);
  }
  if (obj instanceof Error) {
    return Object.create(obj.constructor.prototype);
  }
  let result = Array.isArray(obj) ? [] : {};
  hash.set(obj, result);
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepClone(obj[key], hash);
    }
  }
  return result;
}

export function storeSave(store) {
  const token = localStorage.getItem("token");
  
  if (!token) return; // 如果没有token，直接返回，不执行后续操作

  const saveState = localStorage.getItem("stateSave");
  let parsedState;
  try {
    parsedState = saveState ? JSON.parse(saveState) : {}; // 尝试解析，如果不存在则使用空对象
    store.replaceState(Object.assign(store.state, parsedState)); // 合并状态
  } catch (err) {
    console.error("Error parsing 'stateSave' from localStorage:", err); // 更明确的错误信息
    parsedState = {}; // 如果解析失败，则使用空对象
  }

  // 只在第一次调用时保存当前状态（如果之前没有保存过）
  if (!saveState) {
    localStorage.setItem("stateSave", JSON.stringify(store.state));
  }

  // 订阅状态变化并保存
  store.subscribe((mutation, state) => {
    localStorage.setItem("stateSave", JSON.stringify(state));
  });
}
function all(promises) {
  return new Promise(function (resolve, reject) {
    if (promises.length === 0) {
      resolve([]);
    } else {
      const res = [];
      let count = 0;
      for (var i = 0; i < promises.length; i++) {
        Promise.resolve(
          promises[i]
            .then((item) => {
              res[i] = item;
              count++;
              if (count === promises.length) {
                resolve(res);
              }
            })
            .catch((err) => {
              console.log(err);
            })
        );
      }
    }
  });
}

// 主题（Subject）类
class Subject {
  constructor() {
    this.observers = []; // 观察者列表
  }

  // 添加观察者
  addObserver(observer) {
    this.observers.push(observer);
  }

  // 通知所有观察者
  notify(data) {
    this.observers.forEach((observer) => {
      observer.update(data);
    });
  }
}

// 观察者（Observer）类
class Observer {
  constructor(name) {
    this.name = name;
  }

  // 更新方法
  update(data) {
    console.log(`${this.name} received data: ${data}`);
  }
}

// 创建主题实例
const subject = new Subject();

// 创建观察者实例
const observer1 = new Observer("Observer 1");
const observer2 = new Observer("Observer 2");

// 添加观察者到主题
subject.addObserver(observer1);
subject.addObserver(observer2);

// 主题通知所有观察者
subject.notify("Hello, observers!");
