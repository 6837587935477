import Vue from "vue";
import ElementUI from "element-ui";
import "./assets/icon-font/iconfont.css";
import "./assets/font/iconfont.css";
import "element-ui/lib/theme-chalk/index.css";
import "./utlis/flexble";
import "./assets/banxin.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import SlideVerify from "vue-monoplasty-slide-verify"; // 登录时滑动的验证
import { storeSave } from "./utlis";
import { baseURL } from "@/utlis/request";
import axios from "axios";

Vue.prototype.$uploadUrl = `${baseURL}/u/uploadimg_one`;
Vue.prototype.$uploadperUrl = `${baseURL}/p/upload`;
Vue.prototype.$ShenUploadperUrl = `${baseURL}/u/uploadinfo_one`;
Vue.prototype.$uploadExcel = `${baseURL}/p/import`;

Vue.prototype.$axios = axios;
Vue.prototype.$baseURL = baseURL;
let token = localStorage.getItem("token");
if(token){
  store.dispatch("setUserInfo");
  storeSave(store);
}

// import 'default-passive-events'
function checkAndAssign(arr) {
  if (arr.length > 0) {
    arr.forEach((item) => {
      for (const key in item) {
        if (
          item[key] === null ||
          item[key] === undefined ||
          item[key] === "0" ||
          item[key] === ""
        ) {
          item[key] = "--";
        }
      }
    });
    return arr;
  } else {
    return [];
  }
}
function filterMultipleSelection(arr) {
  const array = [];
  if (arr.length > 1) {
    arr.forEach((item) => array.push(item.customer_id));
    return array;
  } else if (arr.length === 1) {
    return arr[0].c_id;
  } else {
    return "";
  }
}
Vue.directive("click-effect", {
  bind(el, binding, vnode) {
    //console.log(binding, vnode);
    el.addEventListener("mousedown", () => {
      //console.log(el);
      // 切换按钮的按下状态
      vnode.context[binding.expression] = true;
      // 更新按钮样式
      el.classList.add("pressed");
    });

    el.addEventListener("mouseup", () => {
      // 切换按钮的按下状态
      vnode.context[binding.expression] = false;
      // 更新按钮样式
      el.classList.remove("pressed");
    });
  },
});
// 页面布局
ElementUI.Dialog.props.lockScroll.default = false;
Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$myReplace = checkAndAssign;
Vue.prototype.$myFilter = filterMultipleSelection;
Vue.use(SlideVerify);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
