import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "./routes";
Vue.use(VueRouter);
const router = new VueRouter({
  //   mode: 'history',
  routes,
});
// 防止跳转当前路由出现报错的情况
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
export default router;

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("token")) {
      // 获取当前的token是否存在
      next();
    } else {
      next({
        path: "/login", // 将跳转的路由path作为参数，登录成功后跳转到该路由
        query: { redirect: to.fullPath },
      });
    }
  } else {
    // 如果不需要权限校验，直接进入路由界面
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   console.log(to.path, 'to')
//   if (routes.some((item) => {
//     return item.path === to.path
//   })) {
//     next()
//   } else {
//     next('/404')
//   }
// })

// 到时手做个一个判断如何登录了之后不能跳转到登录页面
// to:即将到达的路由    from:即将离开的路由 next:向下执行的方法 //判断改路径里路由是否存在，存在执行否则跳转到404页面
// console.log(to);
// console.log(from);
//   if (to.path == '/login') {
//     // 如果是登录页面,放行
//     next()
//   } else {
//     // 如果不是登录页面,验证token。如果有token,则执行next(),否则跳转到登录页面
//     const token = getCookie('token')
//     // console.log(token);
//     if (token) {
//       next()
//     }
//     if (!token) {
//       next('/login')
//     }
//   }
// 导航守卫
// router.beforeEach((to, from, next) => {
//   console.log(to.path, from.path)
//   if (to.path === '/login' && from.path === '/') {
//     next('/')
//   } else {
//     next()
//   }
// })
