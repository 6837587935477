<template>
  <div id="app">
    <keep-alive exclude="companyMain">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}
img {
  object-fit: contain;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  color: #333333;
  padding: 0 !important;
}

.el-dialog__body {
  padding: 5px 20px !important;
}
.v-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 3005;
}
.el-dialog__header {
  text-align: center;
  border: 1px solid #f0f0f0;
}

.clearfix::after {
  content: "";
  clear: both;
  display: block;
}

i {
  font-style: normal;
}

li {
  cursor: pointer;
}

.cu {
  cursor: pointer;
}

img,
input {
  vertical-align: middle;
}

.el-table .el-table__header thead th {
  background: #f0f7fc;
}

.el-input__icon .el-range__icon .el-icon-date {
  display: block !important;
}
.padding-top {
  padding-top: 80px;
}
.headerAll {
  background: #fff;
  padding: 14px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2001;
}

.el-popover {
  padding: 0 !important;
}

input {
  outline: none;
  border: 0;
}

// .el-icon-error {
//   color: #999 !important;
// }

.el-step__icon {
  font-size: 1;
  color: #fff;
  width: 15px !important;
  height: 15px !important;
}

.el-step__head.is-process {
  color: #fff;
  border: 0;
}

.el-step__title.is-process {
  color: #c0c4cc !important;
}

.el-step__title.is-finish {
  color: #fff !important;
}

.el-tabs__active-bar .is-top {
  width: 70px !important;
}

.cell {
  font-size: 12px;
}

.el-date-editor .el-range-separator {
  padding: 0 !important;
}

.zanwu {
  text-align: center;
  // width: 952px;
  min-width: 300px;
  min-height: 323px;
  background: #ffffff;
  margin: 0 auto;

  img {
    width: 121px;
    height: 81px;
    margin: 80px 0 30px 0;
  }

  p {
    color: #b8b8b8;
    font-size: 14px;
  }
}

.zanwu1 {
  text-align: center;
  // width: 952px;
  background: #ffffff;
  margin: 0 auto;

  img {
    width: 121px;
    height: 81px;
    margin: 80px 0 30px 0;
  }

  p {
    color: #b8b8b8;
    font-size: 14px;
  }
}

.loading {
  text-align: center;
  color: #b8b8b8;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}

.detail {
  cursor: pointer;
  color: #0084ff;
}

.page {
  padding: 20px 0;
  text-align: right;
}

.pressed {
  opacity: 0.8;
  transition: all 0.3s ease;
  cursor: pointer;
}
</style>
